import React, { Component } from "react"
import spinner from "../images/spinner.svg"

class SplashScreen extends Component {
  render() {
    return (
      <div className="splash-screen">
        <img src={spinner} alt="" />
        <h1>Augnablik</h1>
      </div>
    )
  }
}

export default SplashScreen
