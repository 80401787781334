
import { graphql, useStaticQuery } from "gatsby"



export default  () => {
    const data = useStaticQuery(graphql`
    query footerDataQuery{
        allWordpressWpFooters {
            edges {
                node {
                    content
                    title
                    id
                    acf {
                    facebook_message
                    location
                    phone_number
                    email
                    facebook_link
                    location_link
                    }
                }
            }
        }
    }
  `)
  return data
}
