/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import { Helmet } from "react-helmet"
// import WOW from "wow.js/dist/wow.js"

import Header from "./header"
import Footer from "./footer"
import SplashScreen from "./splashScreen"

import "./layout.css"
import "../styles/main.scss"

const Layout = ({ children, language }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <React.Fragment>
      <Helmet>
        <link
          href="https://fonts.googleapis.com/css?family=Lato:100,100i,300,300i,400,400i,700,700i,900,900i|Oswald:200,300,400,500,600,700&display=swap"
          rel="stylesheet"
        />
        <script
          src="https://code.jquery.com/jquery-3.1.0.js"
          integrity="sha256-slogkvB1K3VOkzAI8QITxV3VzpOnkeNVsKvtkYLMjfk="
          crossorigin="anonymous"
        ></script>
        <link
          rel="stylesheet"
          href="https://cdn.jsdelivr.net/npm/animate.css@3.5.2/animate.min.css"
        ></link>
      </Helmet>
      <Header language={language} siteTitle={data.site.siteMetadata.title} />
      <div className="content">
        <main>{children}</main>
      </div>
      <SplashScreen language={language} />
      <Footer language={language} siteTitle={data.site.siteMetadata.title} />
    </React.Fragment>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
