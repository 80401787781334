import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import logo from "../images/frami-icon-black.png"
import footerData from "../queries/staticQueries/footerData"

function Footer({ siteTitle, language }) {
  const data = footerData()
  var footer = {}
  if (language === "en") {
    footer = data.allWordpressWpFooters.edges[0].node
  } else {
    footer = data.allWordpressWpFooters.edges[1].node
  }
  return (
    <footer>
      <div className="footer-inner row wow fadeInUp">
        <div className="col-md-6 col-xs-12">
          <div
            className="footer-info"
            dangerouslySetInnerHTML={{ __html: footer.content }}
          ></div>
          <a className="facebook" href={footer.acf.facebook_link}>
            <p>{footer.acf.facebook_message}!</p>
          </a>
        </div>

        <div className="col-md-3 col-xs-12">
          <a className="location" href={footer.acf.location_link}>
            <p>{footer.acf.location}</p>
          </a>
          <a className="phone" href={"tel:" + footer.acf.phone_number}>
            <p>{footer.acf.phone_number}</p>
          </a>
          <a className="email" href={"mailto:" + footer.acf.email}>
            <p>{footer.acf.email}</p>
          </a>
        </div>
        <div className="col-md-3 logo col-xs-12">
          <Link to="/" alt={siteTitle}>
            {/* <SiteLogo objectFit="cover" objectPosition="50% 50%" alt="" /> */}
            <img src={logo} alt="" />
          </Link>
        </div>
      </div>
      <div className="footer-bottom"></div>
    </footer>
  )
}

Footer.propTypes = {
  siteTitle: PropTypes.string,
}

Footer.defaultProps = {
  siteTitle: ``,
}

export default Footer
