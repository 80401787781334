import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import { getUser, isLoggedIn } from "../services/auth"
import logo from "../images/frami-icon-black.png"

var newsLinkMap = []
newsLinkMap["Afleysingar"] = "/frettir_a"
newsLinkMap["Aðrir"] = "/frettir_o"
newsLinkMap["Leyfishafar"] = "/frettir_l"
newsLinkMap[undefined] = "/frettir"

var newsLinkMapEn = []
newsLinkMapEn["Afleysingar"] = "/news_a"
newsLinkMapEn["Aðrir"] = "/news_o" 
newsLinkMapEn["Leyfishafar"] = "/news_l"
newsLinkMapEn[undefined] = "/news"
//(getUser().role)
const Header = ({ siteTitle, language }) => (
  <header>
    <div className="header-top">
      <Link to={language === "en" ? "/" : "/EN"} className="lang-btn">
        {language === "en" ? "Íslenska" : "En"}
      </Link>
      {
        <Link
          to={
            (language === "en")
              ? isLoggedIn()
                ? "/enApp/profile"
                : "/enApp/log_in"
              : isLoggedIn()
              ? "/app/profile"
              : "/app/log_in"
          }
          className="login-btn"
        >
          {language === "en"
            ? isLoggedIn()
              ? "My profile"
              : "Login"
            : isLoggedIn()
            ? "Mitt svæði"
            : "Innskráning"}
        </Link>
      }
    </div>
    <div
      style={{
        margin: `0 auto`,
        maxWidth: 960,
        padding: `1.45rem 1.0875rem`,
      }}
    >
      <div style={{ width: `160px`, height: `160px`, margin: `auto` }}>
        <Link
          to={language === "en" ? "/EN" : "/"}
          alt={siteTitle}
          style={{
            color: `white`,
            textDecoration: `none`,
          }}
        >
          {/* <Img
            objectFit="cover"
            objectPosition="50% 50%"
            alt=""
          /> */}
          <img src={logo} alt="" />
        </Link>
      </div>
      <div className="main-nav">

        <Link
          to={
            language === "en"
              ? newsLinkMapEn[getUser().role]
              : newsLinkMap[getUser().role]
          }
          activeClassName="current"
          partiallyActive={true}
        >
          {" "}
          {language === "en" ? "News" : "Fréttir"}
        </Link>
        <Link
        to={language === "en" ? "/about" : "/um-felagid"}
        activeClassName="current"
        partiallyActive={true}
      >
        {" "}
        {language === "en" ? "About" : "Um félagið"}
      </Link>
        <Link
          to={language === "en" ? "/bils-en" : "/bils"}
          activeClassName="current"
          partiallyActive={true}
        >
          Bíls
        </Link>
      </div>
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
